import { useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { getDunDataOptions } from "services/setting/general/dunService";
import { PAGE_SIZE } from "utils/constants";

export function useDunDataSettings(params?: any) {
  const queryClient = useQueryClient();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = !searchParams.get("page")
    ? 1
    : Number(searchParams.get("page"));
  const currentLimit = !searchParams.get("itemsPerPage")
    ? 999
    : Number(searchParams.get("itemsPerPage"));

  useEffect(() => {
    queryClient.prefetchQuery({
      queryKey: ["dunData", currentPage, currentLimit],
      queryFn: () => getDunDataOptions({ ...params, page: currentPage, itemsPerPage: currentLimit }),
    });
  }, [currentPage, currentLimit, params, queryClient]);

  const { isLoading, data: dunData } = useQuery({
    queryKey: ["dunData", currentPage, currentLimit],
    queryFn: () => getDunDataOptions({ ...params, page: currentPage, itemsPerPage: currentLimit }),
  });

  // OUTPUT
  return {
    dunData,
    isLoading,
  };
}
