import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { getDunOptions } from "services/setting/general/dunService";
import { PAGE_SIZE } from "utils/constants";

export function useDunSettings(params?: any) {
  const queryClient = useQueryClient();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = !searchParams.get("page")
    ? 1
    : Number(searchParams.get("page"));
  const currentLimit = !searchParams.get("itemsPerPage")
    ? 50
    : Number(searchParams.get("itemsPerPage"));

  const {
    isLoading,
    data: duns,
    error,
  } = useQuery({
    queryKey: ["duns", currentPage, currentLimit, params],
    queryFn: () =>
      params?.paginate === false
        ? getDunOptions({ ...params })
        : getDunOptions({
            ...params,
            page: currentPage,
            itemsPerPage: currentLimit,
          }),
  });

  // OUTPUT
  return {
    duns,
    isLoading,
    error,
  };
}
