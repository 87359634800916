import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Flex, Button, SimpleGrid } from "@chakra-ui/react";

import TextField from "components/fields/TextField";
import SelectField from "components/fields/SelectField";
import FullScreenLoader from "components/loader/FullScreenLoader";

import useOptions from "hooks/setting/general/useOptions";
import { useEditUsahawan } from "hooks/admin/usahawan/useEditUsahawan";
import { useJenisBantuanDataSettings } from "hooks/setting/general/jenisBantuan/useJenisBantuanData";
import { useKategoriProjekDataSettings } from "hooks/setting/general/kategoriProjek/useKategoriProjekData";
import { useSubKategoriProjekDataSettings } from "hooks/setting/general/subKategoriProjek/useSubKategoriProjekData";
import { useNamaProjekDataSettings } from "hooks/setting/general/namaProjek/useNamaProjekData";
import { useTeknologiProjekDataSettings } from "hooks/setting/general/teknologiProjek/useTeknologiProjekData";
import { useJenisProjekDataSettings } from "hooks/setting/general/jenisProjek/useJenisProjekData";
import { useKodKumpulanProjekDataSettings } from "hooks/setting/general/kodKumpulanProjek/useKodKumpulanProjekData";
import { useKategoriPelaksanaDataSettings } from "hooks/setting/general/kategoriPelaksana/useKategoriPelaksanaData";
import { useKaedahPelaksanaDataSettings } from "hooks/setting/general/kaedahPelaksana/useKaedahPelaksanaData";
import { useKumpulanPelaksanaDataSettings } from "hooks/setting/general/kumpulanPelaksana/useKumpulanPelaksanaData";

import { IKodProjekFormValues } from "entities/admin/usahawan";

import { formatDate } from "utils/helpers";
import { useBandarSettings } from "hooks/setting/general/bandar/useBandars";
import { useDaerahSettings } from "hooks/setting/general/daerah/useDaerahs";
import { useDunSettings } from "hooks/setting/general/dun/useDuns";
import { useParlimenSettings } from "hooks/setting/general/parlimen/useParlimens";
import { usePTSettings } from "hooks/setting/general/pt/usePTs";
import { useKampungSettings } from "hooks/setting/general/kampung/useKampungs";
import { useMukimSettings } from "hooks/setting/general/mukim/useMukims";
import { useStesenSettings } from "hooks/setting/general/stesen/useStesens";
import { useSeksyenSettings } from "hooks/setting/general/seksyen/useSeksyens";
import { useKampungDataSettings } from "hooks/setting/general/kampung/useKampungData";
import { useMukimDataSettings } from "hooks/setting/general/mukim/useMukimData";
import { useSeksyenDataSettings } from "hooks/setting/general/seksyen/useSeksyenData";
import { useStesenDataSettings } from "hooks/setting/general/stesen/useStesenData";
import { usePTDataSettings } from "hooks/setting/general/pt/usePTData";
import { useBandarDataSettings } from "hooks/setting/general/bandar/useBandarData";
import { useDaerahDataSettings } from "hooks/setting/general/daerah/useDaerahData";
import { useParlimenDataSettings } from "hooks/setting/general/parlimen/useParlimenData";
import { useDunDataSettings } from "hooks/setting/general/dun/useDunData";

interface FormKodProjekProps {
  action: "edit" | "show";
  initialData?: IKodProjekFormValues;
  negeris?: any;
  daerahs?: any;
  bandars?: any;
  pts?: any;
  duns?: any;
  parlimens?: any;
}

const schema = yup
  .object({
    alamat_projek_1: yup.string().notRequired(),
  })
  .required();

const FormKodProjek: React.FC<FormKodProjekProps> = ({
  action,
  initialData,
}) => {
  const history = useHistory();
  const { negeriOps, statusProjekOps } = useOptions();

  const { ptData, isLoading: isPTLoading } = usePTDataSettings({
    paginate: false,
  });
  const { daerahData, isLoading: isDaerahLoading } = useDaerahDataSettings({
    paginate: false,
  });
  const { parlimenData, isLoading: isParlimenLoading } =
    useParlimenDataSettings({
      paginate: false,
    });
  const { bandarData, isLoading: isBandarLoading } = useBandarDataSettings({
    paginate: false,
  });
  const { dunData, isLoading: isDunLoading } = useDunDataSettings({
    paginate: false,
  });
  const { kampungData, isLoading: isKampungLoading } = useKampungDataSettings({
    paginate: false,
  });
  const { stesenData, isLoading: isStesenLoading } = useStesenDataSettings({
    paginate: false,
  });
  const { mukimData, isLoading: isMukimLoading } = useMukimDataSettings({
    paginate: false,
  });

  const { seksyenData, isLoading: isSeksyenLoading } = useSeksyenDataSettings({
    paginate: false,
  });

  const [daerahOps, setDaerahOps] = useState<any>([]);
  const [pusatTanggungjawabOps, setPusatTanggungjawapOps] = useState<any>([]);
  const [bandarOps, setBandarOps] = useState<any>([]);
  const [parlimenOps, setParlimenOps] = useState<any>([]);
  const [dunOps, setDunOps] = useState<any>([]);
  const [mukimOps, setMukimOps] = useState<any>([]);
  const [seksyenOps, setSeksyenOps] = useState<any>([]);
  const [kampungOps, setKampungOps] = useState<any>([]);
  const [stesenOps, setStesenOps] = useState<any>([]);

  const { editUsahawan, isEditing } = useEditUsahawan();
  const { jenisBantuanData, isLoading: jenisBantuanLoading } =
    useJenisBantuanDataSettings();
  const { kategoriProjekData, isLoading: kategoriProjekLoading } =
    useKategoriProjekDataSettings();
  const { subKategoriProjekData, isLoading: subKategoriProjekLoading } =
    useSubKategoriProjekDataSettings();
  const { namaProjekData, isLoading: namaProjekLoading } =
    useNamaProjekDataSettings();
  const { teknologiProjekData, isLoading: teknologiProjekLoading } =
    useTeknologiProjekDataSettings();
  const { jenisProjekData, isLoading: jenisProjekLoading } =
    useJenisProjekDataSettings();
  const { kodKumpulanProjekData, isLoading: kodKumpulanProjekLoading } =
    useKodKumpulanProjekDataSettings();
  const { kategoriPelaksanaData, isLoading: kategoriPelaksanaLoading } =
    useKategoriPelaksanaDataSettings();
  const { kaedahPelaksanaData, isLoading: kaedahPelaksanaLoading } =
    useKaedahPelaksanaDataSettings();
  const { kumpulanPelaksanaData, isLoading: kumpulanPelaksanaLoading } =
    useKumpulanPelaksanaDataSettings();

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IKodProjekFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      ...initialData,
      tarikh_mula_projek: formatDate(initialData?.tarikh_mula_projek),
      tarikh_mula_berhasil: formatDate(initialData?.tarikh_mula_berhasil),
    },
  });

  const onSubmit = handleSubmit((data) => {
    editUsahawan({
      newUsahawan: {
        projek_bpks: JSON.stringify([data]),
      },
      id: initialData?.id,
    });
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      console.log("----------------")
      console.log(stesenData)
      console.log("----------------")
      if (name === "negeri_id" && ptData && bandarData && parlimenData) {
        setValue("pt_id", null);
        setValue("bandar_id", null);
        setValue("parlimen_id", null);

        const pt = ptData.data
          ?.filter((pt: any) => {
            return (
              parseInt(value.negeri_id.toString()) === parseInt(pt.negeri_id)
            );
          })
          .map((pt: any) => {
            return {
              label: pt.label,
              value: pt.value,
            };
          });

        const bandar = bandarData.data
          ?.filter((bandar: any) => {
            return (
              parseInt(value.negeri_id.toString()) ===
              parseInt(bandar.negeri_id)
            );
          })
          .map((bandar: any) => {
            return {
              label: bandar.label + " - " + bandar.poskod,
              value: bandar.value,
            };
          });

        const parlimen = parlimenData.data
          ?.filter((parlimen: any) => {
            return (
              parseInt(value.negeri_id.toString()) ===
              parseInt(parlimen.negeri_id)
            );
          })
          .map((parlimen: any) => {
            return {
              label: parlimen.label,
              value: parlimen.value,
            };
          });

        setParlimenOps(parlimen);
        setPusatTanggungjawapOps(pt);
        setBandarOps(bandar);
      }

      if (name === "pt_id" && daerahData) {
        setValue("daerah_id", null);

        const daerah = daerahData.data
          ?.filter((daerah: any) => {
            return (
              parseInt(value.pt_id?.toString()) ===
              parseInt(daerah.pusat_tanggungjawap_id)
            );
          })
          .map((daerah: any) => {
            return {
              label: daerah.label,
              value: daerah.value,
            };
          });

        console.log(daerah);

        setDaerahOps(daerah);
      }

      if (name === "daerah_id" && stesenData) {
        setValue("stesen_id", null);

        const stesen = stesenData.data
          ?.filter((stesen: any) => {
            return (
              parseInt(value.daerah_id?.toString()) ===
              parseInt(stesen.daerah_id)
            );
          })
          .map((stesen: any) => {
            return {
              label: stesen.label,
              value: stesen.value,
            };
          });

        console.log(stesen);

        setStesenOps(stesen);
      }

      if (name === "parlimen_id" && dunData) {
        setValue("dun_id", null);

        const dun = dunData.data
          ?.filter((dun: any) => {
            return (
              parseInt(value.parlimen_id?.toString()) ===
              parseInt(dun.parlimen_id)
            );
          })
          .map((dun: any) => {
            return {
              label: dun.label,
              value: dun.value,
            };
          });

        setDunOps(dun);
      }

      if (name === "stesen_id" && mukimData) {
        setValue("mukim_id", null);

        const mukim = mukimData.data
          ?.filter((mukim: any) => {
            return (
              parseInt(value.stesen_id?.toString()) ===
              parseInt(mukim.stesen_id)
            );
          })
          .map((mukim: any) => {
            return {
              label: mukim.label,
              value: mukim.value,
            };
          });

        console.log(mukim);

        setMukimOps(mukim);
      }

      if (name === "mukim_id" && kampungData && seksyenData) {
        setValue("kampung_id", null);
        setValue("seksyen_id", null);

        const kampung = kampungData.data
          ?.filter((kampung: any) => {
            return (
              parseInt(value.mukim_id?.toString()) ===
              parseInt(kampung.mukim_id)
            );
          })
          .map((kampung: any) => {
            return {
              label: kampung.label,
              value: kampung.value,
            };
          });

        const seksyen = seksyenData.data
          ?.filter((seksyen: any) => {
            return (
              parseInt(value.mukim_id?.toString()) ===
              parseInt(seksyen.mukim_id)
            );
          })
          .map((seksyen: any) => {
            return {
              label: seksyen.label,
              value: seksyen.value,
            };
          });

        setSeksyenOps(seksyen);
        setKampungOps(kampung);
      }
    });

    return () => subscription.unsubscribe();
  }, [
    ptData,
    daerahData,
    stesenData,
    mukimData,
    kampungData,
    parlimenData,
    bandarData,
    dunData,
    watch,
    setValue,
    seksyenData,
  ]);

  useEffect(() => {
    if (initialData) {
      if (ptData) {
        const pt = ptData.data
          ?.filter(
            (pt: any) =>
              parseInt(initialData.negeri_id.toString()) ===
              parseInt(pt.negeri_id)
          )
          .map((pt: any) => {
            return {
              label: pt.label,
              value: pt.value,
            };
          });

        setPusatTanggungjawapOps(pt);
      }

      if (bandarData) {
        const bandar = bandarData.data
          ?.filter(
            (bandar: any) =>
              parseInt(initialData.negeri_id.toString()) ===
              parseInt(bandar.negeri_id)
          )
          .map((bandar: any) => {
            return {
              label: bandar.label,
              value: bandar.value,
            };
          });

        setBandarOps(bandar);
      }

      if (parlimenData) {
        const parlimen = parlimenData.data
          ?.filter(
            (parlimen: any) =>
              parseInt(initialData.negeri_id.toString()) ===
              parseInt(parlimen.negeri_id)
          )
          .map((parlimen: any) => {
            return {
              label: parlimen.label,
              value: parlimen.value,
            };
          });

        setParlimenOps(parlimen);
      }

      if (daerahData) {
        const daerah = daerahData.data
          ?.filter(
            (daerah: any) =>
              parseInt(initialData?.pt_id?.toString()) ===
              parseInt(daerah.pt_id)
          )
          .map((daerah: any) => {
            return {
              label: daerah.label,
              value: daerah.value,
            };
          });

        setDaerahOps(daerah);
      }

      if (stesenData) {
        const stesen = stesenData.data
          ?.filter(
            (stesen: any) =>
              parseInt(initialData?.daerah_id?.toString()) ===
              parseInt(stesen.daerah_id)
          )
          .map((stesen: any) => {
            return {
              label: stesen.label,
              value: stesen.value,
            };
          });

        setDaerahOps(stesen);
      }

      if (mukimData) {
        const mukim = mukimData.data
          ?.filter(
            (mukim: any) =>
              parseInt(initialData?.stesen_id?.toString()) ===
              parseInt(mukim.stesen_id)
          )
          .map((mukim: any) => {
            return {
              label: mukim.label,
              value: mukim.value,
            };
          });

        setMukimOps(mukim);
      }

      if (kampungData) {
        const kampung = kampungData.data
          ?.filter(
            (kampung: any) =>
              parseInt(initialData?.mukim_id?.toString()) ===
              parseInt(kampung.mukim_id)
          )
          .map((kampung: any) => {
            return {
              label: kampung.label,
              value: kampung.value,
            };
          });

        setKampungOps(kampung);
      }

      if (dunData) {
        const dun = dunData.data
          ?.filter(
            (dun: any) =>
              parseInt(initialData?.parlimen_id?.toString()) ===
              parseInt(dun.parlimen_id)
          )
          .map((dun: any) => {
            return {
              label: dun.label,
              value: dun.value,
            };
          });

        setDunOps(dun);
      }

      if (seksyenData) {
        const seksyen = seksyenData.data
          ?.filter(
            (seksyen: any) =>
              parseInt(initialData?.mukim_id?.toString()) ===
              parseInt(seksyen.mukim_id)
          )
          .map((dun: any) => {
            return {
              label: seksyen.label,
              value: seksyen.value,
            };
          });

        setSeksyenOps(seksyen);
      }
    }
  }, [
    initialData,
    ptData,
    daerahData,
    stesenData,
    mukimData,
    kampungData,
    parlimenData,
    bandarData,
    dunData,
    seksyenData,
  ]);

  if (
    jenisBantuanLoading ||
    kategoriProjekLoading ||
    subKategoriProjekLoading ||
    namaProjekLoading ||
    teknologiProjekLoading ||
    jenisProjekLoading ||
    kodKumpulanProjekLoading ||
    kategoriPelaksanaLoading ||
    kaedahPelaksanaLoading ||
    kumpulanPelaksanaLoading ||
    isPTLoading ||
    isBandarLoading ||
    isDaerahLoading ||
    isDunLoading ||
    isKampungLoading ||
    isMukimLoading ||
    isStesenLoading ||
    isParlimenLoading ||
    isSeksyenLoading
  ) {
    return <FullScreenLoader />;
  }

  return (
    <Flex mt="10px" mb="1.5rem" flexDirection={"column"}>
      <form onSubmit={onSubmit}>
        <SimpleGrid
          columns={{ base: 2 }}
          gap={{ sm: "6px", md: "18px" }}
          mb={10}
        >
          <SelectField
            label={"Jenis Bantuan"}
            options={jenisBantuanData?.data}
            errorMessage={errors.jenis_bantuan_bpk_id?.message}
            isInvalid={!!errors.jenis_bantuan_bpk_id}
            readOnly={action === "show"}
            {...register("jenis_bantuan_bpk_id")}
          />

          <SelectField
            label={"Kategori Projek"}
            options={kategoriProjekData?.data}
            errorMessage={errors.kategori_projek_bpk_id?.message}
            isInvalid={!!errors.kategori_projek_bpk_id}
            readOnly={action === "show"}
            {...register("kategori_projek_bpk_id")}
          />

          <SelectField
            label={"Sub Kategori Projek"}
            options={subKategoriProjekData?.data}
            errorMessage={errors.sub_kategori_projek_bpk_id?.message}
            isInvalid={!!errors.sub_kategori_projek_bpk_id}
            readOnly={action === "show"}
            {...register("sub_kategori_projek_bpk_id")}
          />

          <SelectField
            label={"Nama Projek"}
            options={namaProjekData?.data}
            errorMessage={errors.nama_projek_bpk_id?.message}
            isInvalid={!!errors.nama_projek_bpk_id}
            readOnly={action === "show"}
            {...register("nama_projek_bpk_id")}
          />

          <SelectField
            label={"Teknologi Projek"}
            options={teknologiProjekData?.data}
            errorMessage={errors.teknologi_projek_bpk_id?.message}
            isInvalid={!!errors.teknologi_projek_bpk_id}
            readOnly={action === "show"}
            {...register("teknologi_projek_bpk_id")}
          />

          <SelectField
            label={"Jenis Projek"}
            options={jenisProjekData?.data}
            errorMessage={errors.jenis_projek_bpk_id?.message}
            isInvalid={!!errors.jenis_projek_bpk_id}
            readOnly={action === "show"}
            {...register("jenis_projek_bpk_id")}
          />

          <SelectField
            label={"Kod Kumpulan Projek"}
            options={kodKumpulanProjekData?.data}
            errorMessage={errors.kod_kumpulan_projek_id?.message}
            isInvalid={!!errors.kod_kumpulan_projek_id}
            readOnly={action === "show"}
            {...register("kod_kumpulan_projek_id")}
          />

          <SelectField
            label={"Kategori Pelaksana"}
            options={kategoriPelaksanaData?.data}
            errorMessage={errors.kategori_pelaksana_id?.message}
            isInvalid={!!errors.kategori_pelaksana_id}
            readOnly={action === "show"}
            {...register("kategori_pelaksana_id")}
          />

          <SelectField
            label={"Kaedah Pelaksana"}
            options={kaedahPelaksanaData?.data}
            errorMessage={errors.kaedah_pelaksana_id?.message}
            isInvalid={!!errors.kaedah_pelaksana_id}
            readOnly={action === "show"}
            {...register("kaedah_pelaksana_id")}
          />

          <SelectField
            label={"Kumpulan Pelaksana"}
            options={kumpulanPelaksanaData?.data}
            errorMessage={errors.kumpulan_pelaksana_id?.message}
            isInvalid={!!errors.kumpulan_pelaksana_id}
            readOnly={action === "show"}
            {...register("kumpulan_pelaksana_id")}
          />

          <TextField
            id="alamat_projek_1"
            label="Alamat 1"
            errorMessage={errors.alamat_projek_1?.message}
            isInvalid={!!errors.alamat_projek_1}
            readOnly={action === "show"}
            {...register("alamat_projek_1")}
          />

          <TextField
            id="alamat_projek_2"
            label="Alamat 2"
            errorMessage={errors.alamat_projek_2?.message}
            isInvalid={!!errors.alamat_projek_2}
            readOnly={action === "show"}
            {...register("alamat_projek_2")}
          />

          <SelectField
            label={"Negeri"}
            options={negeriOps}
            errorMessage={errors.negeri_id?.message}
            isInvalid={!!errors.negeri_id}
            readOnly={action === "show"}
            {...register("negeri_id")}
          />

          <SelectField
            label={"Bandar"}
            options={bandarOps}
            errorMessage={errors.bandar_id?.message}
            isInvalid={!!errors.bandar_id}
            readOnly={action === "show"}
            {...register("bandar_id")}
          />

          <SelectField
            label={"Parlimen"}
            options={parlimenOps}
            errorMessage={errors.parlimen_id?.message}
            isInvalid={!!errors.parlimen_id}
            readOnly={action === "show"}
            {...register("parlimen_id")}
          />

          <SelectField
            label={"Pusat Tanggungjawab"}
            options={pusatTanggungjawabOps}
            errorMessage={errors.pt_id?.message}
            isInvalid={!!errors.pt_id}
            readOnly={action === "show"}
            {...register("pt_id")}
          />

          <SelectField
            label={"Daerah"}
            options={daerahOps}
            errorMessage={errors.daerah_id?.message}
            isInvalid={!!errors.daerah_id}
            readOnly={action === "show"}
            {...register("daerah_id")}
          />

          <SelectField
            label={"Stesen"}
            options={stesenOps}
            errorMessage={errors.stesen_id?.message}
            isInvalid={!!errors.stesen_id}
            readOnly={action === "show"}
            {...register("stesen_id")}
          />

          <SelectField
            label={"Mukim"}
            options={mukimOps}
            errorMessage={errors.mukim_id?.message}
            isInvalid={!!errors.mukim_id}
            readOnly={action === "show"}
            {...register("mukim_id")}
          />

          <SelectField
            label={"Seksyen"}
            options={seksyenOps}
            errorMessage={errors.seksyen_id?.message}
            isInvalid={!!errors.seksyen_id}
            readOnly={action === "show"}
            {...register("seksyen_id")}
          />

          <SelectField
            label={"Kampung"}
            options={kampungOps}
            errorMessage={errors.kampung_id?.message}
            isInvalid={!!errors.kampung_id}
            readOnly={action === "show"}
            {...register("kampung_id")}
          />

          <SelectField
            label={"Dun"}
            options={dunOps}
            errorMessage={errors.dun_id?.message}
            isInvalid={!!errors.dun_id}
            readOnly={action === "show"}
            {...register("dun_id")}
          />

          <TextField
            id="luas_asal_projek_hektar"
            label="Luas Asal Projek (Hektar)"
            errorMessage={errors.luas_asal_projek_hektar?.message}
            isInvalid={!!errors.luas_asal_projek_hektar}
            readOnly={action === "show"}
            {...register("luas_asal_projek_hektar")}
          />

          <TextField
            id="tarikh_mula_projek"
            label="Tarikh Mula Projek"
            type="date"
            errorMessage={errors.tarikh_mula_projek?.message}
            isInvalid={!!errors.tarikh_mula_projek}
            readOnly={action === "show"}
            {...register("tarikh_mula_projek")}
          />

          <TextField
            id="tarikh_mula_berhasil"
            label="Tarikh Mula Berhasil"
            type="date"
            errorMessage={errors.tarikh_mula_berhasil?.message}
            isInvalid={!!errors.tarikh_mula_berhasil}
            readOnly={action === "show"}
            {...register("tarikh_mula_berhasil")}
          />

          <TextField
            id="koordinat_projek"
            label="Koordinat Projek"
            errorMessage={errors.koordinat_projek?.message}
            isInvalid={!!errors.koordinat_projek}
            readOnly={action === "show"}
            {...register("koordinat_projek")}
          />

          <SelectField
            label={"Status Projek"}
            options={statusProjekOps}
            readOnly={action === "show"}
            errorMessage={errors.status_projek?.message}
            isInvalid={!!errors.status_projek}
            {...register("status_projek")}
          />

          <TextField
            id="ketua_pelaksana_id"
            label="Ketua Pelaksana"
            errorMessage={errors.ketua_pelaksana_id?.message}
            isInvalid={!!errors.ketua_pelaksana_id}
            readOnly={action === "show"}
            {...register("ketua_pelaksana_id")}
          />

          <TextField
            id="catatan_pegawai"
            label="Catatan Pegawai"
            errorMessage={errors.catatan_pegawai?.message}
            isInvalid={!!errors.catatan_pegawai}
            readOnly={action === "show"}
            {...register("catatan_pegawai")}
          />
        </SimpleGrid>

        <Flex gap="20px" justifyContent={"flex-end"} mb={"30px"}>
          <Button
            variant="outline"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            colorScheme="gray"
            type={"button"}
            onClick={() => {
              history.goBack();
            }}
          >
            Kembali
          </Button>

          <Button
            variant="brand"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            type="submit"
            isDisabled={isEditing}
          >
            Kemaskini Maklumat Pekebun Kecil
          </Button>
        </Flex>
      </form>
    </Flex>
  );
};

export default FormKodProjek;
