import { useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { getStesenDataOptions } from "services/setting/general/stesenService";
import { PAGE_SIZE } from "utils/constants";

export function useStesenDataSettings(params?: any) {
  const queryClient = useQueryClient();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = !searchParams.get("page")
    ? 1
    : Number(searchParams.get("page"));
  const currentLimit = !searchParams.get("itemsPerPage")
    ? 50
    : Number(searchParams.get("itemsPerPage"));

  useEffect(() => {
    queryClient.prefetchQuery({
      queryKey: ["stesenData", currentPage, currentLimit],
      queryFn: () =>
        getStesenDataOptions({
          ...params,
          page: currentPage,
          itemsPerPage: currentLimit,
        }),
    });
  }, [currentPage, currentLimit, params, queryClient]);

  const { isLoading, data: stesenData } = useQuery({
    queryKey: ["stesenData", currentPage, currentLimit],
    queryFn: () =>
      getStesenDataOptions({
        ...params,
        page: currentPage,
        itemsPerPage: currentLimit,
      }),
  });

  // OUTPUT
  return {
    stesenData,
    isLoading,
  };
}
