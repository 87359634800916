import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { getParlimenOptions } from "services/setting/general/parlimenService";
import { PAGE_SIZE } from "utils/constants";

export function useParlimenSettings(params?: any) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = !searchParams.get("page") ? 1 : Number(searchParams.get("page"));
  const currentLimit = !searchParams.get("itemsPerPage") ? 50 : Number(searchParams.get("itemsPerPage"));

  const { isLoading, data: parlimens, error } = useQuery({
    queryKey: ["parlimens", currentPage, currentLimit, params],
    queryFn: () => params?.paginate === false
    ? getParlimenOptions({ ...params })
    : getParlimenOptions({
        ...params,
        page: currentPage,
        itemsPerPage: currentLimit,
      }),
  });

  // OUTPUT
  return {
    parlimens,
    isLoading,
    error,
  };
}
