import React, { useMemo } from "react";
import { Box, Text, List, Icon } from "@chakra-ui/react";
import { useHistory, NavLink } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { MdEditNote, MdOutlineRemoveRedEye } from "react-icons/md";

import FullScreenLoader from "components/loader/FullScreenLoader";
import ResourceTwo from "components/resourceTwo/ResourceTwo";
import Sorting from "./components/Sorting";

import { useAktivitis } from "hooks/admin/aktiviti/useAktivitis";
import { useCurrentUser } from "hooks/setting/user/useCurrentUser";

import { formatDate } from "utils/helpers";
import { useAktivitiReports } from "hooks/admin/aktiviti/useAktivitiReports";

const columnHelper = createColumnHelper<any>();

export default function Aktiviti() {
  const { isLoading, error, aktivitis } = useAktivitis()
  const { isLoading: isLoading2, error: error2, aktivitis: aktivitis2 } = useAktivitiReports()

  const { currentUser, isLoading: isLoadingUser } = useCurrentUser();

  // Define column headers for CSV
  const fileHeaders = [
    "id",
    "insentif_id",
    "tarikh_aktiviti",
    "nama_usahawan",
    "no_kp_usahawan",
    "no_fon_usahawan",
    "nama_jenis_bantuan",
    "nama_kategori_projek",
    "nama_subkategori_projek",
    "nama_projek",
    "nama_tapak",
    "nama_aktiviti",
  ];

  // Function to convert JSON to CSV string
  function convertJSONToCSV(jsonData: any[], columnHeaders: any[]) {
    // Check if JSON data is empty
    if (jsonData.length === 0) {
      return "";
    }

    // Create headers string
    const headers = columnHeaders.join(",") + "\n";

    // Map JSON data to CSV rows
    const rows = jsonData
      .map((row) => {
        // Map each row to CSV format
        console.log(row);
        return columnHeaders
          .map((field) => {
            if (row[field]) {
              return row[field];
            } else {
              return "";
            }
          })
          .join(",");
      })
      .join("\n");

    // Combine headers and rows
    return headers + rows;
  }

  // Function to initiate CSV download
  function downloadCSV(jsonData: any, headers: any) {
    console.log("downloading....");
    const csvData = convertJSONToCSV(jsonData, headers);

    // Check if CSV data is empty
    if (csvData === "") {
      alert("No data to export");
    } else {
      // Create CSV file and initiate download
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", "reports.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const columns = [
    columnHelper.accessor("id", {
      header: () => <Text variant="tableHeading">ID</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("insentif_id", {
      header: () => <Text variant="tableHeading">ID Insentif</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("tarikh_aktiviti", {
      header: () => <Text variant="tableHeading">Tarikh Aktiviti</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {formatDate(info.getValue(), "dd-MM-yyyy")}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_usahawan", {
      header: () => <Text variant="tableHeading">Nama Usahawan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_kp_usahawan", {
      header: () => <Text variant="tableHeading">No. Kad Pengenalan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_fon_usahawan", {
      header: () => <Text variant="tableHeading">No. Telefon</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_jenis_bantuan", {
      header: () => <Text variant="tableHeading">Jenis Bantuan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_kategori_projek", {
      header: () => <Text variant="tableHeading">Kategori Projek</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_subkategori_projek", {
      header: () => <Text variant="tableHeading">Sub Kategori</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_projek", {
      header: () => <Text variant="tableHeading">Nama Projek</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_tapak", {
      header: () => <Text variant="tableHeading">Tapak</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_aktiviti", {
      header: () => <Text variant="tableHeading">Nama Aktiviti</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
  ];


  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ResourceTwo
        columns={columns}
        data={aktivitis}
        title={"Laporan Aktiviti"}
        allowPagination
        allowDownload
        onClickDownload={() =>
          downloadCSV(aktivitis2?.data?.items ?? [], fileHeaders)
        }
        resourceSortingChildren={<Sorting />}
      />
    </Box>
  );
}
