import React from "react";
import FormUsahawan from "./FormUsahawan";

interface TabUsahawanProps {
  action: "edit" | "show";
  usahawans?: any;
  negeris?: any;
  daerahs?: any;
  bandars?: any;
  pts?: any;
}

const TabUsahawan = ({
  action,
  usahawans,
  negeris,
  daerahs,
  bandars,
  pts,
}: TabUsahawanProps) => {
  return (
    <FormUsahawan
      action={action}
      initialData={usahawans}
      negeris={negeris}
      daerahs={daerahs}
      pts={pts}
    />
  );
};

export default TabUsahawan;
