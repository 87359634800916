import axios from "utils/axiosInstance";

export async function getMukimOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/mukim",
    params,
  });
  return data;
}

export async function getMukimDataOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/mukim/all",
    params,
  });
  return data;
}


