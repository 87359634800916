import React from "react";
import {
  Flex,
  Icon,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/react";

import { MdOutlineFilterAlt, MdSimCardDownload } from "react-icons/md";

import Card from "components/card/Card";

interface ResourceSortingProps {
  allowDownload?: boolean;
  onClickDownload?: () => void;
  children: React.ReactNode;
}

export default function ResourceSorting(props: ResourceSortingProps) {
  const { children, allowDownload = false, onClickDownload } = props;

  return (
    <Accordion px="25px" allowToggle>
      <AccordionItem border={"none"} maxW={"100%"} borderColor={"white"}>
        <Flex align={"center"}>
          <AccordionButton
            flex="1"
            justifyContent={"end"}
            _focus={{ boxShadow: "none" }}
            _hover={{ background: "white" }}
            p={"0px"}
          >
            <Icon
              w="24px"
              h="24px"
              color={"brand.500"}
              as={MdOutlineFilterAlt}
            />
          </AccordionButton>

          {allowDownload && (
            <Button
              p={"0px"}
              variant="no-effects"
              onClick={onClickDownload}
            >
              <Icon
                w="24px"
                h="24px"
                color={"blue.500"}
                as={MdSimCardDownload}
              />
            </Button>
          )}
        </Flex>

        <AccordionPanel p={"0px"}>
          <Card mb={"20px"} border={"1px"} borderColor={"secondaryGray.400"}>
            {children}
          </Card>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
