import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Flex, SimpleGrid, Button } from "@chakra-ui/react";

import SelectField from "components/fields/SelectField";
import TextField from "components/fields/TextField";
import Section from "components/section/Section";

import useOptions from "hooks/setting/general/useOptions";
import { useEditUsahawan } from "hooks/admin/usahawan/useEditUsahawan";

import { IUsahawanFormValues } from "entities/admin/usahawan";

import { formatDate } from "utils/helpers";

interface FormUsahawanProps {
  action: "edit" | "show";
  initialData?: IUsahawanFormValues;
  negeris?: any;
  daerahs?: any;
  bandars?: any;
  pts?: any;
}

const schema = yup.object({}).required();

const FormUsahawan: React.FC<FormUsahawanProps> = ({
  action,
  initialData,
  negeris,
  daerahs,
  bandars,
  pts,
}) => {
  const history = useHistory();

  const [ptOptions, setPtOptions] = useState<any>([]);
  const [daerahOptions, setDaerahOptions] = useState<any>([]);

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IUsahawanFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      name: initialData?.profile?.name,
      email: initialData?.email,
      updated_at: formatDate(initialData?.updated_at, "dd-MM-yyyy"),
      no_kp: initialData?.profile?.no_kp,
      tarikh_lahir: initialData?.profile?.tarikh_lahir,
      no_fon_rumah: initialData?.profile?.no_fon_rumah,
      no_hp: initialData?.profile?.no_hp,
      id_jantina: initialData?.profile?.id_jantina,
      id_bangsa: initialData?.profile?.id_bangsa,
      id_agama: initialData?.profile?.id_agama,
      id_etnik: initialData?.profile?.id_etnik,
      id_tahap_pendidikan: initialData?.profile?.id_tahap_pendidikan,
      id_sijil_tertinggi: initialData?.profile?.id_sijil_tertinggi,
      id_status_kahwin: initialData?.profile?.id_status_kahwin,
      umur_semasa: initialData?.profile?.umur_semasa,
      kemahiran: initialData?.profile?.kemahiran,
      pengalaman: initialData?.profile?.pengalaman,
      negeri_id: initialData?.profile?.negeri_id,
      daerah_id: initialData?.profile?.daerah_id,
      pusat_tanggungjawap_id: initialData?.profile?.pusat_tanggungjawap_id,
    },
  });

  const {
    negeriOps,
    agamaOps,
    bangsaOps,
    etnikOps,
    jantinaOps,
    tahapPendidikanOps,
    sijilTertinggiOps,
    statusKahwinOps,
  } = useOptions();
  const { isEditing, editUsahawan } = useEditUsahawan();

  const onSubmit = handleSubmit((data) => {
    const { name, no_kp, updated_at, maklumat_dari, ...otherProperties } = data;
    const profile = {
      id: initialData?.profile?.id,
      ...otherProperties,
    };

    editUsahawan({
      newUsahawan: {
        profile: JSON.stringify(profile),
      },
      id: initialData?.profile?.id,
    });
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "negeri_id" && pts) {
        setValue("pusat_tanggungjawap_id", null);

        console.log(pts)
        console.log(value.negeri_id)

        const pt = pts
          .filter((pt: any) => {
            console.log(value.negeri_id, pt.negeri_id, value.negeri_id === pt.negeri_id)
            return parseInt(value.negeri_id) === parseInt(pt.negeri_id);
          })
          .map((pt: any) => {
            return {
              label: pt.keterangan,
              value: pt.id,
            };
          });

        setPtOptions(pt);
      }

      if (name === "pusat_tanggungjawap_id" && daerahs) {
        setValue("daerah_id", null);

        const daerah = daerahs
          .filter((daerah: any) => {
            return (
              parseInt(value.pusat_tanggungjawap_id) === parseInt(daerah.pusat_tanggungjawap_id)
            );
          })
          .map((daerah: any) => {
            return {
              label: daerah.daerah,
              value: daerah.id,
            };
          });

        console.log(daerah);

        setDaerahOptions(daerah);
      }
    });

    return () => subscription.unsubscribe();
  }, [negeris, pts, daerahs, watch, setValue]);

  useEffect(() => {
    if (initialData) {
      if (pts) {
        const pt = pts
          ?.filter((pt: any) => parseInt(initialData.profile?.negeri_id) === parseInt(pt.negeri_id))
          .map((pt: any) => {
            return {
              label: pt.keterangan,
              value: pt.id,
            };
          });

        setPtOptions(pt);
      }

      if (daerahs) {
        const daerah = daerahs
          ?.filter(
            (daerah: any) =>
              parseInt(initialData.profile?.pusat_tanggungjawap_id) === parseInt(daerah.pt_id)
          )
          .map((daerah: any) => {
            return {
              label: daerah.daerah,
              value: daerah.id,
            };
          });

        setDaerahOptions(daerah);
      }
    }
  }, [initialData, pts, daerahs]);

  return (
    <Flex px="25px" mt="1rem" mb="1.5rem" flexDirection={"column"}>
      <form onSubmit={onSubmit}>
        <Section title="Maklumat Peribadi">
          <SimpleGrid
            columns={{ base: 2 }}
            gap={{ sm: "6px", md: "18px" }}
            mb={10}
          >
            <TextField
              id="name"
              label="Nama"
              readOnly
              autoComplete="nope"
              {...register("name")}
            />

            <TextField
              id="email"
              label="Email"
              type="email"
              isInvalid={!!errors.email}
              errorMessage={errors.email?.message}
              readOnly={action === "show"}
              autoComplete="nope"
              {...register("email")}
            />

            <TextField
              id="updated_at"
              label="Kemaskini Terakhir"
              readOnly
              {...register("updated_at")}
            />

            <TextField
              id="no_kp"
              label="No. Kad Pengenalan"
              readOnly
              {...register("no_kp")}
            />

            <TextField
              id="tarikh_lahir"
              label="Tarikh Lahir"
              type="date"
              isInvalid={!!errors.tarikh_lahir}
              errorMessage={errors.tarikh_lahir?.message}
              readOnly={action === "show"}
              {...register("tarikh_lahir")}
            />

            <TextField
              id="no_fon_rumah"
              label="No. Telefon Rumah"
              isInvalid={!!errors.no_fon_rumah}
              errorMessage={errors.no_fon_rumah?.message}
              readOnly={action === "show"}
              {...register("no_fon_rumah")}
            />

            <TextField
              id="no_hp"
              label="No. Telefon Bimbit"
              isInvalid={!!errors.no_hp}
              errorMessage={errors.no_hp?.message}
              readOnly={action === "show"}
              {...register("no_hp")}
            />

            <SelectField
              label={"Jantina"}
              options={jantinaOps}
              isInvalid={!!errors.id_jantina}
              errorMessage={errors.id_jantina?.message}
              readOnly={action === "show"}
              {...register("id_jantina")}
            />

            <SelectField
              label={"Bangsa"}
              options={bangsaOps}
              isInvalid={!!errors.id_bangsa}
              errorMessage={errors.id_bangsa?.message}
              readOnly={action === "show"}
              {...register("id_bangsa")}
            />

            <SelectField
              label={"Agama"}
              options={agamaOps}
              isInvalid={!!errors.id_agama}
              errorMessage={errors.id_agama?.message}
              readOnly={action === "show"}
              {...register("id_agama")}
            />

            <SelectField
              label={"Etnik"}
              options={etnikOps}
              isInvalid={!!errors.id_etnik}
              errorMessage={errors.id_etnik?.message}
              readOnly={action === "show"}
              {...register("id_etnik")}
            />

            <SelectField
              label="Tahap Pendidikan"
              options={tahapPendidikanOps}
              isInvalid={!!errors.id_tahap_pendidikan}
              errorMessage={errors.id_tahap_pendidikan?.message}
              readOnly={action === "show"}
              {...register("id_tahap_pendidikan")}
            />

            <SelectField
              label="Sijil Tertinggi"
              options={sijilTertinggiOps}
              isInvalid={!!errors.id_sijil_tertinggi}
              errorMessage={errors.id_sijil_tertinggi?.message}
              readOnly={action === "show"}
              {...register("id_sijil_tertinggi")}
            />

            <SelectField
              label="Status Perkahwinan"
              options={statusKahwinOps}
              isInvalid={!!errors.id_status_kahwin}
              errorMessage={errors.id_status_kahwin?.message}
              readOnly={action === "show"}
              {...register("id_status_kahwin")}
            />

            <TextField
              id="umur_semasa"
              label="Umur Semasa"
              readOnly
              {...register("umur_semasa")}
            />

            <TextField
              id="kemahiran"
              label="Kemahiran"
              isInvalid={!!errors.kemahiran}
              errorMessage={errors.kemahiran?.message}
              readOnly={action === "show"}
              {...register("kemahiran")}
            />

            <TextField
              id="pengalaman"
              label="Pengalaman"
              isInvalid={!!errors.pengalaman}
              errorMessage={errors.pengalaman?.message}
              readOnly={action === "show"}
              {...register("pengalaman")}
            />

            <SelectField
              label={"Negeri"}
              options={negeriOps}
              isInvalid={!!errors.negeri_id}
              errorMessage={errors.negeri_id?.message}
              readOnly={action === "show"}
              {...register("negeri_id")}
            />

            <SelectField
              label={"Pusat Tanggungjawab"}
              options={ptOptions}
              isInvalid={!!errors.pusat_tanggungjawap_id}
              errorMessage={errors.pusat_tanggungjawap_id?.message}
              readOnly={action === "show"}
              {...register("pusat_tanggungjawap_id")}
            />

            <SelectField
              label={"Daerah"}
              options={daerahOptions}
              isInvalid={!!errors.daerah_id}
              errorMessage={errors.daerah_id?.message}
              readOnly={action === "show"}
              {...register("daerah_id")}
            />

            <TextField
              id="maklumat_dari"
              label="Maklumat Dari"
              readOnly
              {...register("maklumat_dari")}
            />
          </SimpleGrid>
        </Section>

        <Flex gap="20px" justifyContent={"flex-end"} mb="20px">
          <Button
            variant="outline"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            colorScheme="gray"
            type={"button"}
            onClick={() => {
              history.goBack();
            }}
          >
            Kembali
          </Button>

          {action !== "show" && (
            <Button
              variant="brand"
              fontSize={"sm"}
              fontWeight={"500"}
              minW={"183px"}
              type="submit"
              isDisabled={isEditing}
            >
              Kemaskini Maklumat Peribadi
            </Button>
          )}
        </Flex>
      </form>
    </Flex>
  );
};

export default FormUsahawan;
