import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { getBandarOptions } from "services/setting/general/bandarService";
import { PAGE_SIZE } from "utils/constants";

export function useBandarSettings(params?: any) {
  const queryClient = useQueryClient();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = !searchParams.get("page") ? 1 : Number(searchParams.get("page"));
  const currentLimit = !searchParams.get("itemsPerPage") ? 50 : Number(searchParams.get("itemsPerPage"));

  const { isLoading, data: bandars, error } = useQuery({
    queryKey: ["bandars", currentPage, currentLimit, params],
    queryFn: () => params?.paginate === false
    ? getBandarOptions({ ...params })
    : getBandarOptions({
        ...params,
        page: currentPage,
        itemsPerPage: currentLimit,
      }),
  });

  // OUTPUT
  return {
    bandars,
    isLoading,
    error,
  };
}
