import { Flex, Text, Box, Image } from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";
import logoRISDA from "assets/img/layout/logoRISDA.png";

export function SidebarBrand() {
  return (
    <Flex alignItems="center" flexDirection="column">
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        ml={{ base: 0, md: "5" }}
        gap="10px"
        my="32px"
      >
        <Image src={logoRISDA}  alt="Dan Abramov" />
        <Text color={"brand.500"} fontSize="lg" fontWeight="800">
          RISDAPreneur Management System (RMS) 3.0
        </Text>
      </Box>

      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
