import axios from "utils/axiosInstance";

export async function getSeksyenOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/seksyen",
    params,
  });
  return data;
}

export async function getSeksyenDataOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/seksyen/all",
    params,
  });
  return data;
}


