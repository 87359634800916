import React from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Center,
} from "@chakra-ui/react";

import FormKodProjek from "./FormKodProjek";

interface TabKodProjekProps {
  action: "edit" | "show";
  kodProjeks?: any;
}

const TabKodProjek = ({
  action,
  kodProjeks = [],
}: TabKodProjekProps) => {
  console.log(kodProjeks);
  return (
    <Box px="25px">
      <Accordion variant="main" allowToggle>
        {kodProjeks.length === 0 && <Center py={20}>Tiada Maklumat</Center>}

        {kodProjeks.map((kodProjek: any, index: number) => (
          <AccordionItem key={`${kodProjek.id}-${index}`}>
            <h2>
              <AccordionButton bg={"gray.100"}>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  fontWeight={"semibold"}
                >
                  {kodProjek?.nama_projek_bpk?.nama_projek} :{" "}
                  {kodProjek?.kod_kumpulan_projek?.nama_kod}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>

            <AccordionPanel>
              <FormKodProjek
                action={action}
                initialData={kodProjek}
              />
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};

export default TabKodProjek;
