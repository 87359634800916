import React from "react";
import { Box, Text, Link, List, Icon } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { MdOutlineRemoveRedEye } from "react-icons/md";

import FullScreenLoader from "components/loader/FullScreenLoader";
import ResourceTwo from "components/resourceTwo/ResourceTwo";
import Sorting from "./components/Sorting";

import { usePendapatans } from "hooks/admin/pendapatan/usePendapatans";

const columnHelper = createColumnHelper<any>();

const Pendapatan = () => {
  const { isLoading, error, pendapatans } = usePendapatans();

  const columns = [
    columnHelper.accessor("syarikat_perniagaan_type", {
      header: () => <Text variant="tableHeading">Jenis</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()?.replace('App\\Models\\', '')}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_syarikat_perniagaan", {
      header: () => <Text variant="tableHeading">Nama Syarikat/Perniagaan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("ssm_syarikat_perniagaan", {
      header: () => <Text variant="tableHeading">SSM Syarikat Perniagaan</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_telefon", {
      header: () => <Text variant="tableHeading">No. Telefon</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("tahun_pendapatan", {
      header: () => <Text variant="tableHeading">Tahun Pendapatan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue().map((d:any) => <>{d.tahun + ' - RM ' + d.pendapatan}<br/></>)}
        </Text>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ResourceTwo
        columns={columns}
        data={pendapatans}
        title={"Senarai Pendapatan"}
        allowPagination
        // resourceSortingChildren={<Sorting />}
      />
    </Box>
  );
};

export default Pendapatan;
