import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from "@chakra-ui/react";

import Card from "components/card/Card";
import FullScreenLoader from "components/loader/FullScreenLoader";
import TabUsahawan from "./components/Tab/TabUsahawan";
import TabSyarikat from "./components/Tab/TabSyarikat";
import TabPerniagaan from "./components/Tab/TabPerniagaan";
import TabKodProjek from "./components/Tab/TabKodProjek";
import TabJenisPerniagaan from "./components/Tab/TabJenisPerniagaan";
import TabPekebunKecil from "./components/Tab/TabPekebunKecil";
import TabTetapan from "./components/Tab/TabTetapan";

import { useUsahawan } from "hooks/admin/usahawan/useUsahawan";
import { useNegeriSettings } from "hooks/setting/general/negeri/useNegeris";
import { useDaerahSettings } from "hooks/setting/general/daerah/useDaerahs";
import { useBandarSettings } from "hooks/setting/general/bandar/useBandars";
import { useDunSettings } from "hooks/setting/general/dun/useDuns";
import { useParlimenSettings } from "hooks/setting/general/parlimen/useParlimens";
import { usePTSettings } from "hooks/setting/general/pt/usePTs";

interface LocationState {
  tabIndex?: number;
}

const UsahawanPanel = () => {
  const [action, setAction] = useState<"show" | "edit">("show");
  const [tabIndex, setTabIndex] = useState(0);
  const { usahawan, isInitialLoading, isLoading } = useUsahawan();

  const location = useLocation<LocationState>();
  const { negeris, isLoading: isNegeriLoading } = useNegeriSettings({
    paginate: false,
  });
  const { pts, isLoading: isPTLoading } = usePTSettings({ paginate: false });
  const { daerahs, isLoading: isDaerahLoading } = useDaerahSettings({
    paginate: false,
  });
  const { bandars, isLoading: isBandarLoading } = useBandarSettings({
    paginate: false,
  });
  const { duns, isLoading: isDunLoading } = useDunSettings({ paginate: false });
  const { parlimens, isLoading: isParlimenLoading } = useParlimenSettings({
    paginate: false,
  });

  useEffect(() => {
    if (location.pathname.includes("show")) {
      setAction("show");
    } else if (location.pathname.includes("kemaskini")) {
      setAction("edit");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.state && location.state.tabIndex !== undefined) {
      setTabIndex(location.state.tabIndex);
    }
  }, [location.state]);

  if (
    isInitialLoading ||
    isLoading ||
    isNegeriLoading ||
    isDaerahLoading ||
    isBandarLoading ||
    isDunLoading ||
    isParlimenLoading
  ) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Tabs variant="main" defaultIndex={tabIndex}>
          <TabList>
            <Tab>Usahawan</Tab>
            <Tab>Syarikat</Tab>
            <Tab>Perniagaan</Tab>
            <Tab>Insentif BPK</Tab>
            <Tab>Insentif BPU</Tab>
            <Tab>Pekebun Kecil</Tab>
            <Tab>Tetapan</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <TabUsahawan
                action={action}
                negeris={negeris?.data?.items}
                daerahs={daerahs?.data?.items}
                bandars={bandars?.data?.items}
                pts={pts?.data?.items}
                usahawans={usahawan?.data}
              />
            </TabPanel>
            <TabPanel>
              <TabSyarikat
                action={action}
                syarikats={usahawan?.data?.syarikats}
                negeriData={negeris?.data?.items}
                daerahData={daerahs?.data?.items}
                bandarData={bandars?.data?.items}
                dunData={duns?.data?.items}
                parlimenData={parlimens?.data?.items}
              />
            </TabPanel>
            <TabPanel>
              <TabPerniagaan
                action={action}
                perniagaans={usahawan?.data?.perniagaans}
                negeriData={negeris?.data?.items}
                daerahData={daerahs?.data?.items}
                bandarData={bandars?.data?.items}
                dunData={duns?.data?.items}
                parlimenData={parlimens?.data?.items}
              />
            </TabPanel>
            <TabPanel>
              <TabKodProjek
                action={action}
                kodProjeks={usahawan?.data?.projek_bpks}
              />
            </TabPanel>
            <TabPanel>
              <TabJenisPerniagaan
                action={action}
                jenisPerniagaans={usahawan?.data?.bpu_insentifs}
              />
            </TabPanel>
            <TabPanel>
              <TabPekebunKecil
                action={action}
                pekebunKecils={usahawan?.data?.pekebun_kecils}
              />
            </TabPanel>
            <TabPanel>
              <TabTetapan
                action={action}
                usahawanId={usahawan?.data?.id}
                isActive={usahawan?.data?.is_active}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Card>
    </Box>
  );
};

export default UsahawanPanel;
