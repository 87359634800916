import React from "react";
import { Box, Text, Link, List, Icon } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { MdOutlineRemoveRedEye } from "react-icons/md";

import FullScreenLoader from "components/loader/FullScreenLoader";
import ResourceTwo from "components/resourceTwo/ResourceTwo";
import Sorting from "./components/Sorting";

import { usePendapatans } from "hooks/admin/pendapatan/usePendapatans";
import { usePendapatanReports } from "hooks/admin/pendapatan/usePendapatanReports";

const columnHelper = createColumnHelper<any>();

const Pendapatan = () => {
  const { isLoading, error, pendapatans } = usePendapatans();
  const {
    isLoading: isLoading2,
    error: error2,
    pendapatans: pendapatans2,
  } = usePendapatanReports();

  // Define column headers for CSV
  const fileHeaders = [
    "syarikat_perniagaan_type",
    "nama_syarikat_perniagaan",
    "ssm_syarikat_perniagaan",
    "tahun_pendapatan",
  ];

  // Function to convert JSON to CSV string
  function convertJSONToCSV(jsonData: any[], columnHeaders: any[]) {
    // Check if JSON data is empty
    if (jsonData.length === 0) {
      return "";
    }

    // Create headers string
    const headers = columnHeaders.join(",") + "\n";

    // Map JSON data to CSV rows
    const rows = jsonData
      .map((row) => {
        // Map each row to CSV format
        console.log(row);
        return columnHeaders
          .map((field) => {
            if (row[field]) {
              if (field === "tahun_pendapatan") {
                return row[field].map(
                  (d: any) => d.tahun + " - RM " + d.pendapatan + "\n"
                );
              }
              return row[field]
            } else {
              return "";
            }
          })
          .join(",");
      })
      .join("\n");

    // Combine headers and rows
    return headers + rows;
  }

  // Function to initiate CSV download
  function downloadCSV(jsonData: any, headers: any) {
    console.log("downloading....");
    const csvData = convertJSONToCSV(jsonData, headers);

    // Check if CSV data is empty
    if (csvData === "") {
      alert("No data to export");
    } else {
      // Create CSV file and initiate download
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", "reports.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const columns = [
    columnHelper.accessor("syarikat_perniagaan_type", {
      header: () => <Text variant="tableHeading">Jenis</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()?.replace("App\\Models\\", "")}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_syarikat_perniagaan", {
      header: () => (
        <Text variant="tableHeading">Nama Syarikat/Perniagaan</Text>
      ),
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("ssm_syarikat_perniagaan", {
      header: () => <Text variant="tableHeading">SSM Syarikat Perniagaan</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_telefon", {
      header: () => <Text variant="tableHeading">No. Telefon</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("tahun_pendapatan", {
      header: () => <Text variant="tableHeading">Tahun Pendapatan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue().map((d: any) => (
            <>
              {d.tahun + " - RM " + d.pendapatan}
              <br />
            </>
          ))}
        </Text>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ResourceTwo
        columns={columns}
        data={pendapatans}
        title={"Laporan Pendapatan"}
        allowPagination
        allowDownload
        onClickDownload={() =>
          downloadCSV(pendapatans2?.data?.items ?? [], fileHeaders)
        }
        resourceSortingChildren={<Sorting />}
      />
    </Box>
  );
};

export default Pendapatan;
